<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <!-- <el-form-item>
          <el-input v-model="searchParams.tanker_id" placeholder="加油机" />
        </el-form-item> -->
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-input v-model="searchParams.cpu_code" placeholder="监控微处理器编号" />
        </el-form-item> -->
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import { QueryLockHistoryList, ExportLockHistoryList } from '@/api/lock';

import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      tableLoading: true,
      gas: '',
      factory: '',
      searchParams: {
        site_id: '',
        tanker_id: '',
        cpu_code: ''
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 64,
          align: 'center',
          fixed: true,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          fixed: true,
          minWidth: 210
        },
        {
          title: '加油机名称',
          key: 'tanker_name',
          minWidth: 100
        },
        {
          title: '端口编号',
          key: 'port',
          align: 'center'
          // width: 120
        },
        {
          title: '油枪编号',
          key: 'gun_num',
          align: 'center'
          // width: 140
        },
        {
          title: '监控微处理号',
          key: 'cpu_code',
          minWidth: 105
          // width: 80
        },
        // {
        // title: '主板内枪号',
        // key: 'gun_num2'
        // width: 80
        // },
        {
          title: '真实油量',
          key: 'quantity',
          align: 'center'
        },
        {
          title: '显示油量',
          key: 'error_quantity',
          align: 'center'
        },
        {
          title: '异常时间',
          key: 'error_time',
          align: 'center',
          width: 180
        },
        {
          title: '上传时间',
          key: 'time',
          align: 'center',
          width: 180
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    const queryData = this.$router.history.current.query;

    if (Object.keys(queryData).length > 0) {
      this.searchParams = {
        ...queryData,
        site_id: queryData.site_id * 1
      };
    }

    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize, ...other }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams,
        ...other
      };
      QueryLockHistoryList(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      ExportLockHistoryList(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleView(e) {
      console.log(e);
    }
  }
};
</script>

<style lang="scss" scoped></style>
